import { v4 as uuidV4 } from 'uuid';

import { ecgTypeLabels } from 'common/constants/ecgEnums';

import { eventAiaCheckEdge } from '../constants/enums';

export const searchEcgEvents = (analyzedStream, abType, edge) => {
  let event = null;
  const events = [];
  const acceptanceEdge = edge || eventAiaCheckEdge[abType];

  analyzedStream.forEach((ePart, index) => {
    if (!ePart || ePart <= acceptanceEdge) {
      return;
    }

    if (!event) {
      event = {
        id: uuidV4(),
        aiLabel: `${ecgTypeLabels[abType]} - AIA`,
        abnormalityType: abType,
        dateFrom: ePart.time,
        dateTo: ePart.time,
        avgAIAValue: 0,
        AIAValues: [],
      };
    }

    event.AIAValues.push(ePart.value);
    event.dateTo = ePart.time;

    const nextPart = analyzedStream[index + 1];

    if (!nextPart) {
      event.avgAIAValue =
        event.AIAValues.reduce((acc, value) => acc + value, 0) /
        event.AIAValues.length;
      events.push(event);
      event = null;
    }
  });

  return events;
};
