import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

export const eventAiaCheckEdge = {
  [abnormalityTypeEnum.ve]: 0.5,
  [abnormalityTypeEnum.sve]: 0.8,
  [abnormalityTypeEnum.veBigeminy]: 0.5,
  [abnormalityTypeEnum.pPeak]: 0.95,
  [abnormalityTypeEnum.qPeak]: 0.95,
  [abnormalityTypeEnum.rPeak]: 0.95,
  [abnormalityTypeEnum.sPeak]: 0.95,
  [abnormalityTypeEnum.tPeak]: 0.95,
};

export const peaksAiaColors = {
  [abnormalityTypeEnum.pPeak]: '#0000FF',
  [abnormalityTypeEnum.qPeak]: '#008000',
  [abnormalityTypeEnum.rPeak]: '#FF0000',
  [abnormalityTypeEnum.sPeak]: '#800080',
  [abnormalityTypeEnum.tPeak]: '#FFA500',
};
