import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';

export const findIndexPrevOrNextEvent = (time, events) => {
  if (events?.length <= 1) {
    return {};
  }

  const sortedEvents = events.sort(
    (a, b) => Number(new Date(a.dateFrom)) - Number(new Date(b.dateFrom))
  );

  const index = getFirstEqualOrAfterTimeIndex(sortedEvents, time, 'dateFrom');

  const prevIndex = index < 0 ? index : index - 1;
  const nextIndex = index < 0 ? index : index + 1;

  const prevEvent = events[prevIndex];
  const nextEvent = events[nextIndex];

  return { prevEvent, nextEvent };
};
