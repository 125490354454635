import * as d3 from 'd3';
import moment from 'moment/moment';
import rangeValues from 'lodash/range';

const DEFAULT_MARGIN = { top: 0, left: 0, bottom: 0, right: 0 };
const DEFAULT_PADDING = { top: 5, left: 0, bottom: 5, right: 0 };

// graph component utils
export const marginPaddingWrapper = (value, isPadding = false) => {
  if (typeof value === 'number') {
    return { top: value, right: value, bottom: value, left: value };
  }

  if (!value) {
    return isPadding ? DEFAULT_PADDING : DEFAULT_MARGIN;
  }

  return value;
};

export const xGen = (dat, key, range) => {
  const data = dat || [];
  const isDomain = data.length === 2;
  const domain = isDomain ? data : d3.extent(data, (d) => new Date(d[key]));

  return d3.scaleTime().domain(domain).range(range);
};

export const yGen = (dat, key, range, zoomLevel = 1) => {
  const data = dat || [];

  const isDomain = data.length === 2;
  const [min, max] = isDomain
    ? data
    : [d3.min(data, (d) => d[key]), d3.max(data, (d) => d[key])];

  const mid = (min + max) / 2;
  const diff = Math.abs(max - min) / 2 / zoomLevel;

  const domain = [mid - diff, mid + diff];

  return d3.scaleLinear().domain(domain).range(range);
};

export const lineGen = (keys, y) => (data, x) => {
  const line = d3
    .line()
    .x((d) => x(new Date(d[keys.x])))
    .y((d) => y(d[keys.y]))
    .defined((d) => {
      const yVal = d?.[keys.y];
      return typeof yVal === 'number' && !Number.isNaN(yVal);
    });

  return line(data);
};

export const xAxisGridGen = (height, margin, speed) => (g, x) => {
  const grid = g.attr('transform', `translate(0,${margin.top})`).call(
    d3
      .axisBottom(x)
      .ticks(d3.timeMillisecond.every(200 * speed))
      .tickSize(height - margin.bottom - margin.top)
  );

  grid.selectAll('g.tick > text').remove();

  return grid;
};

export const yAxisGridGen =
  (width, height, margin, duration, amplitude) => (g, y) => {
    const [min, max] = y.domain();

    const yTicks = rangeValues(min, max, amplitude * 100);

    const grid = g
      .attr('transform', `translate(${width - margin.right},0)`)
      .call(
        d3
          .axisLeft(y)
          .tickValues(yTicks)
          .tickSize(width - margin.left - margin.right)
      );

    grid.selectAll('g.tick text').remove();

    return grid;
  };

export const xTransform = (rect, xPos) => {
  rect.attr('transform', `translate(${xPos}, 0)`);
};

export const rulerGen = (height, paddingTop) => (g, xLeftPos, xRightPos) =>
  g.attr(
    'points',
    `${xLeftPos},${
      height + paddingTop
    } ${xLeftPos},${paddingTop} ${xRightPos},${paddingTop} ${xRightPos},${
      height + paddingTop
    }`
  );

// graph component data utils
export const getBeatIndex = (range, point) => {
  const pointDate = moment(point);

  return range.findIndex((r) => pointDate.isSameOrBefore(r.time));
};

export const getBeatsRangeHRAvg = (beats, range) => {
  const [s, e] = range;

  const startPoint = s instanceof Date ? s : new Date(s);
  const endPoint = e instanceof Date ? e : new Date(e);

  const isOverflow =
    endPoint.getTime() > new Date(beats[beats.length - 1].time).getTime();
  const startIndex = Math.max(getBeatIndex(beats, startPoint), 1);
  const endIndex = isOverflow
    ? beats.length - 1
    : Math.max(getBeatIndex(beats, endPoint), 1);

  const beatRange = beats.slice(startIndex, endIndex);
  const sum = beatRange.reduce((acc, item) => acc + item.value, 0);

  return sum ? Math.round(sum / beatRange.length) : 0;
};
