import React from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextInput from '../../../components/simpleInputs/TextInput';

const ThresholdSelector = ({
  name,
  label,
  value,
  onThresholdChange,
  versionsData = [],
  selectedVersion,
  onVersionChange,
}) => {
  return (
    <Grid item key={name} container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <TextInput
          name={name}
          size="small"
          label={`${label} Threshold`}
          value={value}
          onChange={onThresholdChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          key={name}
          label="Version"
          value={selectedVersion}
          onChange={onVersionChange}
          size="small"
          fullWidth
        >
          {versionsData.map((versionData) => {
            return (
              <MenuItem
                key={`${name}${versionData.version}`}
                value={versionData.version}
              >
                {`${versionData.version} - ${versionData.description}`}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
};

export default ThresholdSelector;
