import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import * as d3 from 'd3';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import {
  getEcg,
  getYMinMaxValues,
} from 'common/modules/eventStrip/ducks/selectors';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { eventTriageStripSettings } from 'common/modules/eventStrip/constants';
import { getRecordsOverlappedByRange } from 'common/utils/helpers/date';
import Button from 'common/components/buttons/ModalActionButton';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import moment from 'moment';
import Header from './Header';
import EventsNav from './EventsNav';
import LoaderOverlay from './LoaderOverlay';
import EventStripGraph from './EventStripGraph';
import useNotify from '../../Notification/hooks/useNotify';
import useRouterInfo from '../../../hooks/useRouterInfo';
import { eventAiaCheckEdge, peaksAiaColors } from '../constants/enums';
import { searchEcgEvents } from '../utils/ecgUtils';
import ThresholdSelector from './ThresholdSelector';

const eventTypesToAnalyze = [
  abnormalityTypeEnum.ve,
  abnormalityTypeEnum.sve,
  abnormalityTypeEnum.veBigeminy,
  abnormalityTypeEnum.pPeak,
  abnormalityTypeEnum.qPeak,
  abnormalityTypeEnum.rPeak,
  abnormalityTypeEnum.sPeak,
  abnormalityTypeEnum.tPeak,
];

const defaultStripSettings = eventTriageStripSettings.reduce(
  (acc, val) => ({
    ...acc,
    [val.name]: val.defaultValue,
  }),
  {}
);

const AnalyzeModal = ({ resource, center, onClose }) => {
  const notify = useNotify();
  const routerInfo = useRouterInfo();
  const procedureId = routerInfo?.params?.id;
  const graphContainer = useRef(null);
  const [aiEvents, setAiEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxWidth, setMaxWidth] = useState(700);
  const [visibleRange, setVisibleRange] = useState(null);
  const [analyzedBeats, setAnalyzedBeats] = useState([]);
  const [stripSettings, setStripSettings] = useState(defaultStripSettings);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedBeatRange, setSelectedBeatRange] = useState(null);
  const [acceptanceEdges, setAcceptanceEdge] = useState(eventAiaCheckEdge);
  const [currentCenter, setCenter] = useState(center);
  const [versions, setVersions] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState({});
  const selectedVersionRef = useRef(selectedVersion);

  const ecg = useSelector(getEcg(resource));
  const { min: yMin, max: yMax } = useSelector(getYMinMaxValues(resource));

  const xDomain = useMemo(() => d3.extent(ecg, (d) => new Date(d.time)), [ecg]);

  const yDomain = useMemo(() => [yMin - 225, yMax + 225], [yMin, yMax]);

  const focusOptions = useMemo(() => {
    return {
      center: currentCenter || center,
      domain: {
        x: xDomain,
        y: yDomain,
      },
      duration: 10,
    };
  }, [center, xDomain, yDomain, currentCenter]);

  const visibleEvents = useMemo(
    () =>
      getRecordsOverlappedByRange(
        aiEvents,
        [visibleRange].flat().filter(Boolean)
      ),
    [aiEvents, visibleRange]
  );

  const getModelsVersions = useCallback(async () => {
    const modelVersionsPromises = eventTypesToAnalyze.map(async (abType) => {
      const { data } = await dataProvider.createOne(
        resources.aia.modelVersions,
        {
          query: {
            abnormalityType: abType,
          },
        }
      );
      const { result = [] } = data || {};
      return { abnormality: abType, versionsData: result };
    });

    const resolvedModelVersions = await Promise.all(modelVersionsPromises);
    const versionsMap = new Map();
    const initialSelectedVersions = {};

    resolvedModelVersions.forEach(({ abnormality, versionsData }) => {
      versionsMap.set(abnormality, versionsData);
      initialSelectedVersions[abnormality] =
        versionsData.length > 0
          ? versionsData[versionsData.length - 1].version
          : '';
    });

    setVersions(versionsMap);
    setSelectedVersion(initialSelectedVersions);
  }, []);

  const updateSelectedVersion = (name, version) => {
    const updatedVersions = {
      ...selectedVersion,
      [name]: version,
    };

    setSelectedVersion(updatedVersions);
  };

  const startAnalyze = useCallback(
    async ({ edges }) => {
      if (!procedureId) {
        return;
      }

      setLoading(true);
      setAiEvents([]);

      const aiaPeaks = [];

      try {
        const allTypesChecks = eventTypesToAnalyze.map(async (abType) => {
          const edge = +(edges || eventAiaCheckEdge)[abType];
          const { data } = await dataProvider.createOne(resources.aia.predict, {
            query: {
              abnormalityType: abType,
              dateFrom: moment(center).subtract(2.5, 'minutes').toISOString(),
              dateTo: moment(center).add(2.5, 'minutes').toISOString(),
              procedureId,
              threshold: edge,
              modelVersion: selectedVersionRef.current[abType],
            },
          });

          const { result = [] } = data || {};

          const modifiedData = result.map((r) => (r.value > edge ? r || 0 : 0));

          if (
            abType === abnormalityTypeEnum.pPeak ||
            abType === abnormalityTypeEnum.qPeak ||
            abType === abnormalityTypeEnum.rPeak ||
            abType === abnormalityTypeEnum.sPeak ||
            abType === abnormalityTypeEnum.tPeak
          ) {
            const currentPeaks = {
              peaks: [modifiedData].flat().filter(Boolean),
              color: peaksAiaColors[abType],
            };
            aiaPeaks.push(currentPeaks);
            return;
          }

          const events = searchEcgEvents(modifiedData, abType, edge);

          setAiEvents((prevEvents) => [...prevEvents, ...events]);
        });

        await Promise.all(allTypesChecks);
      } finally {
        setLoading(false);
        setAnalyzedBeats(aiaPeaks);
      }
    },
    // eslint-disable-next-line
    [center, procedureId]
  );

  const handleChangeThreshold = (e) => {
    const val = String(e.target.value).replace(/[^0-9.]/g, '');

    setAcceptanceEdge((v) => ({ ...v, [e.target.name]: val }));
  };

  const handleVersionChange = (name, version) => {
    updateSelectedVersion(name, version);
  };

  useEffect(() => {
    selectedVersionRef.current = selectedVersion;
  }, [selectedVersion]);

  const graphClickHandler = useCallback((bRange) => {
    setSelectedBeatRange(bRange);
    setSelectedEvent(null);
  }, []);

  const groupedEvents = useMemo(
    () => groupBy(aiEvents, 'abnormalityType'),
    [aiEvents]
  );

  const countLabel = useMemo(() => {
    const eventKeys = Object.keys(groupedEvents);

    if (!eventKeys.length) {
      return '0';
    }

    return eventKeys
      .map((abT) => `${abT} - ${groupedEvents[abT].length}`)
      .join(', ');
  }, [groupedEvents]);

  const handleManualAnalyze = (visibleOnly = false) => {
    const notValid = Object.keys(acceptanceEdges).filter((k) => {
      const edge = +acceptanceEdges[k];

      return !edge || edge < 0 || edge > 1;
    });

    if (notValid.length) {
      const notValidValues = notValid.join(', ');

      notify.error(
        `Please set correct Acceptance Threshold value for ${notValidValues}. The value must be in range [0, 1]`
      );
      return null;
    }

    return startAnalyze({
      visibleOnly,
      edges: acceptanceEdges,
    });
  };

  useEffect(() => {
    if (!graphContainer.current) {
      return;
    }

    setMaxWidth(graphContainer.current.clientWidth - 32);
  }, [graphContainer]);

  useEffect(() => {
    getModelsVersions();
    startAnalyze({});
  }, [startAnalyze, getModelsVersions]);

  return (
    <Card
      style={{
        flex: 1,
        position: 'relative',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      {loading && <LoaderOverlay />}

      <Header
        isModalView
        resource={resource}
        selectedEvent={selectedEvent}
        stripSettings={stripSettings}
        setStripSettings={setStripSettings}
      />

      <Grid
        item
        container
        direction="column"
        ref={graphContainer}
        style={{ position: 'relative', padding: '8px 16px' }}
      >
        <EventStripGraph
          height={350}
          width={maxWidth}
          onClick={graphClickHandler}
          settings={stripSettings}
          resource={resource}
          aiEvents={visibleEvents}
          selectedEvent={selectedEvent}
          position={selectedBeatRange}
          visibleRange={visibleRange}
          focusOptions={focusOptions}
          analyzedBeats={analyzedBeats}
          setVisibleRange={setVisibleRange}
          handleEventSelect={setSelectedEvent}
        />
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: '8px 16px' }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="flex-end"
          style={{ paddingBottom: '8px', gap: 24 }}
        >
          <Grid item container style={{ gap: 10 }}>
            <Grid container style={{ gap: 10 }}>
              {eventTypesToAnalyze.map((name) => {
                return (
                  <ThresholdSelector
                    key={name}
                    name={name}
                    label={`${name} Threshold (${eventAiaCheckEdge[name]})`}
                    value={acceptanceEdges[name]}
                    onThresholdChange={handleChangeThreshold}
                    versionsData={versions === null ? [] : versions.get(name)}
                    selectedVersion={selectedVersion[name] || ''}
                    onVersionChange={(event) =>
                      handleVersionChange(name, event.target.value)
                    }
                  />
                );
              })}
            </Grid>

            <Typography>
              <span style={{ backgroundColor: 'blue', color: 'white' }}>
                P peak
              </span>{' '}
              <span style={{ backgroundColor: 'green', color: 'white' }}>
                Q peak
              </span>{' '}
              <span style={{ backgroundColor: 'red', color: 'white' }}>
                R peak
              </span>{' '}
              <span style={{ backgroundColor: 'purple', color: 'white' }}>
                S peak
              </span>{' '}
              <span style={{ backgroundColor: 'orange', color: 'white' }}>
                T peak
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          alignItems="center"
          xs={6}
          style={{ marginTop: 10 }}
        >
          <Button onClick={handleManualAnalyze} disabled={loading}>
            Analyze
          </Button>

          <Typography style={{ paddingLeft: 16 }}>
            AIA events: {loading ? 'Analysis...' : countLabel}
          </Typography>
        </Grid>

        <Grid item style={{ display: 'flex', gap: 16 }}>
          <EventsNav
            setCenter={setCenter}
            allEvents={aiEvents}
            visibleRange={visibleRange}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            selectedBeatRange={selectedBeatRange}
            setSelectedBeatRange={setSelectedBeatRange}
          />

          <Button onClick={onClose}>Close</Button>
        </Grid>
      </Grid>
    </Card>
  );
};

AnalyzeModal.propTypes = {
  center: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
};

export default AnalyzeModal;
